import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import Helmet from 'react-helmet';
import { Audio } from 'react-loader-spinner'
import Select from "react-select";
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import jQuery from 'jquery';


export default class Home extends Component {
    
    constructor(){
        super();
        this.state={
          notifications:[],
          blogs:[],
          courses:[],
          programs:[],
          countries: [], 
          trendingCourses:[],
          states: [],
          stateValue:'',
          mobile:''
        }
      }

      selectCountry = (val) => {
        this.setState({ stateValue: '' });
        const state_arr = countryFile.s_a[val.key].split("|"); 
        const stateArray = [];
        stateArray.push({
          value: '', 
          label: 'Select'
        });
        for (var i = 0; i < state_arr.length; i++) {
          stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
          });
        }
        this.setState({ states: stateArray });
    
        var Country = val.value;
        let check = checkNumber.checkNumber(Country,this.state.mobile);
        if(check === 2){
          this.setState({mobile:''});
        }
      }
      
        selectRegion = (val) => {
          if(val.value !== '' && val.value !== null){
            this.setState({stateValue:val});
          } else {
            this.setState({stateValue:''});
          }
        }
        updateMobile = (value) => {
          this.setState({mobile:value});
        }
     
      async componentDidMount() {
        jQuery('.more-btn').click(function(e) {
          e.preventDefault();
          jQuery(this).text(function(i, t) {
            return t == 'Read less' ? 'Read more' : 'Read less';
          }).prev('.more-content').slideToggle()
        });
        const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        this.setState({countries:countryArray});
        this.getList();
        
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/home-page-data')
            .then(res=>{
                this.setState({
                    notifications:res.data.notifications,
                    blogs:res.data.blogs,
                    trendingCourses:res.data.trendingcourses,
                    // programs:res.data.programs,
                    colleges:res.data.colleges
                },()=>{
                    // console.log(this.state)
                })
            })
            .catch(err=>{
                console.log(err);
            })
      }

      resetForm(){
        document.getElementById('home-page-middle-form').reset();
      }

      handleSubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            jQuery('#enquiry').modal('hide');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

    render(){
        const {notifications}=this.state;
        const {blogs}=this.state;
        const {courses}=this.state;
        const {programs}=this.state;
        const {colleges}=this.state;
        const {trendingCourses}=this.state;
        const { countries, states, stateValue , mobile} = this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                    <title>Top Nursing Colleges in Bangalore | Direct Admissions Open for 2024-25 in Bangalore</title>
                    <meta name="description" content="Discover the Top nursing colleges in Bangalore for 2024-25. Apply for Direct Admissions in Nursing Courses for BSc, MSc, GNM and get enrolled hassle-free."/>
                    <meta name="keywords" content="Top 10  nursing colleges in bangalore, top colleges for nursing in bangalore, best nursing colleges bangalore, bangalore nursing bangalore, top 5 colleges in bangalore for nursing"/>
            </Helmet>
            <div id="content" className="site-content ">
              <div id="content-inner" className="site-content-inner ">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                      <article id="post-2133" className="post-2133 page type-page status-publish hentry">
                          <header className="single-entry-header tm-hide">
                            <h2 className="entry-title">Nursing Colleges</h2>
                          </header>
                          <div className="entry-content">
                            <div className="vc_row-full-width vc_clearfix"></div>
                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom hidden-sm hidden-md hidden-lg visible-xs">
                                <div className="vc_row container">
                                  <div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum">
                                      <div className="vc_column-inner  ">
                                        <div className="wpb_wrapper">
                                            <div className="res-slider-form">
                                              <div className="widget">
                                                  <h4 className="widget-title text-center">Quick Enquiry</h4>
                                                  <p className="text-center">Apply now to get admission for Nursing</p>
                                                  <div className="widget-content">
                                                  <form onSubmit={this.handleSubmit}>
                                                        <div className="row">
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" name="name" placeholder="Name *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="email" name="email" placeholder="Email *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" name="phone" placeholder="Phone *" required/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12">
                                                              <div className="text-block">
                                                                <input type="text" name="course" placeholder="Course *" required/>
                                                                <input type="hidden" name="source" value="2"/> 
                                                              </div>
                                                          </div>
                                                          <div className="col-xs-12 pad-5">
                                                            <div className="text-block">
                                                                <Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
                                                            </div>
                                                          </div>
                                                          <div className="col-xs-12 pad-5">
                                                            <div className="text-block">
                                                                <Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
                                                            </div>
                                                          </div>
                                                          <div className="col-xs-12 text-center">
                                                              <input type="submit" value="Submit" className="btn submit"/> 
                                                          </div>
                                                        </div>
                                                    </form>
                                                  </div>
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                            </div>

                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                              <div className="vc_row container">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner  ">
                                      <div className="wpb_wrapper">
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-responsive-custom-10018428 tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 vc_col-has-fill tm-zindex-0">
                                                <div className="vc_column-inner vc_custom_1567594802767 ">
                                                  <div className="wpb_wrapper">
                                                      <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1567595065320">
                                                        <figure className="wpb_wrapper vc_figure">
                                                            <div className="vc_single_image-wrapper vc_box_border_grey"><img data-src="assets/img/about-home.jpg" className="vc_single_image-img attachment-full lozad" alt="Top Nursing Colleges in Bangalore" src="assets/img/about-home.jpg" data-loaded="true" /></div>
                                                        </figure>
                                                      </div>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-7 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h2 className="tm-custom-heading ">What is Nursing?</h2>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                        <div className="tm-cta3-content-wrapper">
                                                            <p>The field of Nursing can be considered as the glue that holds a patient's healthcare journey together. Across the patient’s entire experience and also wherever there is someone in immediate need of care, Nurses work tirelessly to care for individuals and families, identify and protect the needs of an individual and help to provide a comfortable and healthy living.</p>
                                                            <p>Beyond the popular reputation for compassion and dedication, lies a highly specialized profession, which is constantly evolving to address the needs of an individual and the society. From ensuring the accurate diagnosis, constant care, health management, to the continuous education of the public about critical health issues; nurses are key players in safeguarding public health.</p>
                                                            <p>Nursing can be described as both an art and a science; as an example of working with a Heart and a Mind. At its heart, Nursing upholds a fundamental respect for human dignity and an intuition for a patient’s needs. This is supported by the mind, in the form of rigorous core learning, training, clinical practice and skill development. Due to the vast range of specializations and complex skills in the Nursing profession, each Nurse will have specific strengths, passions and expertise that are unique.</p>
                                                            <div className="more-content" style={{display: 'none'}}>
                                                              <p>In assessing a patient, Nurses do not just consider test results; they also use their sense of analysis and judgement. Through continuous critical thinking, Nurses use their judgment to evaluate objective medical data, analyse the subjective experience of a patient’s biological, physical and behavioral needs and manage the health of the patient through care. This ensures that every patient, from city hospital, community health center, state prison to summer camp, receives the best possible care regardless of their background.</p>
                                                              <p>In a field as diverse and varied as Nursing, there is no typical answer. Responsibilities range from making acute treatment diagnosis to providing vaccinations in schools. The key core characteristic in every role is the skill, experience, drive and determination that it takes to be a Nurse. Through long-term monitoring of a patient’s external health, medical tests and behavior and knowledge-based expertise and skills of the Nurse, they are highly qualified to take important decisions related to a patient's health and wellbeing.</p>
                                                            </div>
                                                            <a href="#" className="btn more more-btn">Read more</a>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <hr/>
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h3 className="tm-custom-heading ">Top Nursing Courses in Bangalore</h3>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                        <div className="tm-cta3-content-wrapper">
                                                            <p>Every year, multiple students opt for Nursing Courses at different colleges in India. Through these courses, students are educated and trained in healthcare management, awareness and mastering different specializations of the field.</p>
                                                            <p><b>There are various courses offered in Nursing under different degrees:</b></p>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="row multi-columns-row themetechmount-boxes-row-wrapper nursing-boxes">
                                            <div className="tm-box-col-wrapper col-lg-3 col-sm-6 col-md-3 col-xs-12 ">
                                                <article className="themetechmount-boxes themetechmount-box-course themetechmount-boxe-view-top-image themetechmount-course-box-view-top-image">
                                                  <div className="themetechmount-post-item">
                                                      <div className="themetechmount-box-content">
                                                        <div className="themetechmount-post-item-inner">
                                                            <div className="tm-featured-wrapper tm-lp_course-featured-wrapper">
                                                              <img width="300" height="300" src="/assets/img/box-item-1.jpg" className="attachment-themetechmount-img-coursebox size-themetechmount-img-coursebox wp-post-image lozad" alt="Undergraduate Nursing Courses"/>
                                                            </div>
                                                        </div>
                                                        <div className="themetechmount-box-bottom-content">
                                                            <div className="tm-bottom-content-inner">
                                                              <div className="themetechmount-box-title">
                                                                  <h6>Undergraduate Nursing Courses</h6>
                                                              </div>
                                                              <div className="themetechmount-box-desc-text">
                                                                  <ul>
                                                                    <li><Link to="/courses/bsc-nursing">BSc Nursing</Link></li>
                                                                    <li><Link to="/courses/post-basic-bsc-nursing">Post Basic (PB) BSc Nursing</Link></li>
                                                                  </ul>
                                                              </div>
                                                            </div>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </article>
                                            </div>
                                            <div className="tm-box-col-wrapper col-lg-3 col-sm-6 col-md-3 col-xs-12 ">
                                                <article className="themetechmount-boxes themetechmount-box-course themetechmount-boxe-view-top-image themetechmount-course-box-view-top-image">
                                                  <div className="themetechmount-post-item">
                                                      <div className="themetechmount-box-content">
                                                        <div className="themetechmount-post-item-inner">
                                                            <div className="tm-featured-wrapper tm-lp_course-featured-wrapper">
                                                              <img width="300" height="300" src="/assets/img/box-item-2.jpg" className="attachment-themetechmount-img-coursebox size-themetechmount-img-coursebox wp-post-image lozad" alt="Postgraduate Nursing Courses"/>
                                                            </div>
                                                        </div>
                                                        <div className="themetechmount-box-bottom-content">
                                                            <div className="tm-bottom-content-inner">
                                                              <div className="themetechmount-box-title">
                                                                  <h6>Postgraduate Nursing Courses</h6>
                                                              </div>
                                                              <div className="themetechmount-box-desc-text">
                                                                  <ul>
                                                                    <li><Link to="/courses/msc-nursing">MSc Nursing</Link></li>
                                                                  </ul>
                                                              </div>
                                                            </div>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </article>
                                            </div>
                                            <div className="tm-box-col-wrapper col-lg-3 col-sm-6 col-md-3 col-xs-12 ">
                                                <article className="themetechmount-boxes themetechmount-box-course themetechmount-boxe-view-top-image themetechmount-course-box-view-top-image">
                                                  <div className="themetechmount-post-item">
                                                      <div className="themetechmount-box-content">
                                                        <div className="themetechmount-post-item-inner">
                                                            <div className="tm-featured-wrapper tm-lp_course-featured-wrapper">
                                                              <img width="300" height="300" src="/assets/img/box-item-3.jpg" className="attachment-themetechmount-img-coursebox size-themetechmount-img-coursebox wp-post-image lozad" alt="Diploma Nursing Courses"/>
                                                            </div>
                                                        </div>
                                                        <div className="themetechmount-box-bottom-content">
                                                            <div className="tm-bottom-content-inner">
                                                              <div className="themetechmount-box-title">
                                                                  <h6>Diploma Nursing Courses</h6>
                                                              </div>
                                                              <div className="themetechmount-box-desc-text">
                                                                  <ul>
                                                                    <li><Link to="/courses/gnm">General Nursing and Midwifery (GNM)</Link></li>
                                                                  </ul>
                                                              </div>
                                                            </div>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </article>
                                            </div>
                                            <div className="tm-box-col-wrapper col-lg-3 col-sm-6 col-md-3 col-xs-12 ">
                                                <article className="themetechmount-boxes themetechmount-box-course themetechmount-boxe-view-top-image themetechmount-course-box-view-top-image">
                                                  <div className="themetechmount-post-item">
                                                      <div className="themetechmount-box-content">
                                                        <div className="themetechmount-post-item-inner">
                                                            <div className="tm-featured-wrapper tm-lp_course-featured-wrapper">
                                                              <img width="300" height="300" src="/assets/img/box-item-4.jpg" className="attachment-themetechmount-img-coursebox size-themetechmount-img-coursebox wp-post-image lozad" alt="Certificate Nursing Courses"/>
                                                            </div>
                                                        </div>
                                                        <div className="themetechmount-box-bottom-content">
                                                            <div className="tm-bottom-content-inner">
                                                              <div className="themetechmount-box-title">
                                                                  <h6>Certificate Nursing Courses</h6>
                                                              </div>
                                                              <div className="themetechmount-box-desc-text">
                                                                  <ul>
                                                                    <li>Certification Programs</li>
                                                                  </ul>
                                                              </div>
                                                            </div>
                                                        </div>
                                                      </div>
                                                  </div>
                                                </article>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className=" tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h3 className="tm-custom-heading" style={{marginTop: '20px'}}>Duration of Nursing Courses</h3>
                                                              <p>The duration of nursing courses varies from 2 to 4 years depending on the type of course and degree level.</p>
                                                            </div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>

                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293975 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-textcolor-white tm-bgimage-position-left_center tm-bg tm-bgimage-yes">
                              <div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
                              <div className="vc_row container vc_row-o-equal-height vc_row-flex">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 vc_col-has-fill tm-zindex-2 hidden-lg hidden-md hidden-sm visible-xs">
                                    <div className="vc_column-inner vc_custom_1567664749067 tm-col-bgimage-yes tm-responsive-custom-94735818">
                                      <div className="tm-col-wrapper-bg-layer tm-bg-layer tm-bgimage-position-center_center">
                                          <div className="tm-bg-layer-inner"></div>
                                      </div>
                                      <div className="wpb_wrapper eligibility-img"> 
                                        <img data-src="assets/img/eligibility.jpg" className="lozad"/> 
                                      </div>
                                    </div>
                                </div>
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
                                    <div className="vc_column-inner vc_custom_1567664250316 tm-responsive-custom-75919887">
                                      <div className="wpb_wrapper">
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567774236927 tm-responsive-custom-64911954 tm-zindex-0">
                                            <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-bgcolor-skincolor vc_custom_1567774428913 tm-responsive-custom-71053255 tm-zindex-0">
                                                <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-10 tm-zindex-0">
                                                  <div className="vc_column-inner vc_custom_1567658690491 ">
                                                      <div className="wpb_wrapper">
                                                        <h3 className="tm-custom-heading vc_custom_1567661288254 nursing-eligiblity">Eligibility criteria for Nursing</h3>
                                                      </div>
                                                  </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                                <div className="vc_column-inner tm-responsive-custom-12751771">
                                                  <div className="wpb_wrapper eligibility-list">
                                                      <ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The eligibility criteria varies for each type of course and degree level.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The candidate should have passed 10+2 (or equivalent) in Science stream with Physics, Chemistry, Biology and English subjects.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The candidate should be of minimum 17 years to maximum of 35 years of age on the date of admission.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The eligibility depends on the category for Karnataka ST/SC/Category-I candidates.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The candidate should have passed from a reputed and recognized board university (Karnataka PUC/ ISC/ CBSE/ State Boards).</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">If the candidate is an NRI/Foreigner, they should submit documents to prove their Foreign/NRI Status - Passport, Study Permit, School Certificate, etc.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The candidate should have done registration with the Nursing Council (if applicable).</span></li>
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                    <div className="wpb_column tm-column vc_column_container vc_col-sm-10 vc_col-has-fill tm-zindex-2 hidden-xs">
                                      <div className="vc_column-inner vc_custom_1567664749067 tm-col-bgimage-yes tm-responsive-custom-94735818">
                                          <div className="tm-col-wrapper-bg-layer tm-bg-layer tm-bgimage-position-center_center">
                                            <div className="tm-bg-layer-inner"></div>
                                          </div>
                                          <div className="wpb_wrapper eligibility-img"> 
                                            <img src="assets/img/eligibility.jpg" className="lozad"/> 
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>
                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                              <div className="vc_row container">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h5 className="tm-custom-heading ">Affiliations and Approvals</h5>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Recognition by the Government of Karnataka</b></span></li>
                                                        <p>The Nursing courses offered by colleges in Bangalore should be approved by the Government of Karnataka.</p>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Affiliated to Rajiv Gandhi University of Health Sciences (RGUHS)</b></span></li>
                                                        <p>Rajiv Gandhi University of Health Sciences is a government central university in Bangalore established in 1996 by the Government of Karnataka and recognized by the University Grants Commission (UGC) for the regulation of higher education in health sciences in Karnataka. The university is the largest health sciences university in India and is located in Jayanagar.</p>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Approval of Indian Nursing Council (INC)</b></span></li>
                                                        <p>The Indian Nursing Council is an autonomous national regulatory body for Nursing education in India. It was started in 1947 with the objective of establishing a uniform standard of education and training for Nurses, Midwives and Healthcare Workers. The council is also responsible to acknowledge and grant various Nursing qualifications and recognize a potential Nursing college or institution.</p>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Approval of the Karnataka State Diploma in Nursing Examination Board (KSDNEB)</b></span></li>
                                                        <p>The Karnataka State Diploma in Nursing Examination Board is a state regulatory body for Nursing education in Karnataka. It was re-established in 1991 with the objective of enabling Nursing colleges or institutions to provide high-quality Nursing education, practice and research opportunities in accordance with the Indian Nursing Council. The board is also responsible to prepare Nurses for effective participation in national healthcare delivery programs.</p>
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-5 vc_col-sm-offset-1 tm-zindex-0 margin-15px-991-colum" id="notifications">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <hr className="hidden-sm visible-xs"/>
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h5 className="tm-custom-heading "><span className="bell fa fa-bell"></span> Notifications</h5>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <ul className="m-0 tm-recent-post-list scrollable-notification">
                                                        {notifications && notifications.length > 0 && notifications.map((notification, index) => {
                                                          return (
                                                          <li className="tm-recent-post-list-li" key={`n${notification.id}`}>
                                                            <Link to={`/notifications/${notification.slug}`}>
                                                              <img width="150" height="150" src={`/storage/notifications/${notification.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image lozad" alt={notification.title} src={`/storage/notifications/${notification.image2}`} data-loaded="true"/></Link>
                                                                <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                                                <span className="post-date">{this.notificationDate(new Date(notification.updated_at))}</span>
                                                          </li>
                                                          );
                                                        })}
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>
                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293976 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-left_center tm-bg tm-bgimage-yes">
                              <div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
                              <div className="vc_row container vc_row-o-equal-height vc_row-flex">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-8 col-sm-offset-2 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
                                    <div className="apply-now">
                                      <div className="widget">
                                          <h4 className="widget-title text-center">Apply now</h4>
                                          <p className="text-center">Apply now to get admission for Nursing</p>
                                          <div className="widget-content">
                                            <form className="course-form clearfix" id="home-page-middle-form" onSubmit={this.handleSubmit}>
                                                <div className="row">
                                                  <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                        <input type="text" name="name" placeholder="Name *" required/> 
                                                      </div>
                                                  </div>
                                                  <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                        <input type="email" name="email" placeholder="Email *" required/> 
                                                      </div>
                                                  </div>
                                                  <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                      <input type="text" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" name="phone" placeholder="Phone *" required/>  
                                                      </div>
                                                  </div>
                                                  <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                        <input type="text" name="course" placeholder="Course *" required/>
                                                        <input type="hidden" name="source" value="2"/> 
                                                      </div>
                                                  </div>
                                                  <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                          <Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
                                                      </div>
                                                  </div>
                                                  <div className="col-xs-12 col-sm-6">
                                                      <div className="text-block">
                                                          <Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
                                                      </div>
                                                  </div>
                                                  <div className="col-xs-12 col-sm-6 text-center hidden-xs">
                                                      <input type="button" value="Reset" onClick={this.resetForm} className="btn clear-btn"/> 
                                                  </div>
                                                  <div className="col-xs-12 col-sm-6 text-center">
                                                      <input type="submit" value="Submit" className="btn submit"/> 
                                                  </div>
                                                </div>
                                            </form>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>
                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                              <div className="vc_row container">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h5 className="tm-custom-heading ">Blogs</h5>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            
                                            {blogs && blogs.length > 0 && blogs.map((blogloop, index) => {
                                              return (
                                              <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0" key={`b${index}`}>
                                                  <div className="vc_column-inner  ">
                                                    <div className="wpb_wrapper">
                                                        <ul className="m-0 tm-recent-post-list">
                                                        {blogloop && blogloop.length > 0 && blogloop.map((blog, index2) => {
                                                          return (
                                                          <li className="tm-recent-post-list-li" key={`bl${blog.id}`}>
                                                              <Link to={`/blogs/${blog.slug}`}><img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/></Link>
                                                              <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
                                                              <span className="post-date">{this.notificationDate(new Date(blog.updated_at))}</span>
                                                          </li>
                                                          )
                                                        })}
                                                        </ul>
                                                    </div>
                                                  </div>
                                              </div>
                                              )
                                            })}
                                            
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-5 vc_col-sm-offset-1 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <hr className="hidden-sm visible-xs"/>
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h5 className="tm-custom-heading ">Similar Trending courses</h5>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                      {trendingCourses && trendingCourses.length > 0 && trendingCourses.map((trendingCourse, index2) => {
                                                        return (
                                                          <li key={`cs${trendingCourse.id}`}>
                                                            <i className="tm-skincolor fa fa-arrow-circle-right"></i>
                                                            <span className="tm-list-li-content">
                                                            <Link to={trendingCourse.url} target="_blank">{trendingCourse.course}</Link></span>
                                                          </li>
                                                        )
                                                      })}
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-10 text-center">
                                                <hr/>
                                                <a className="apply-btn" href="#enquiry" data-toggle="modal">Apply now</a> 
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                              <div className="vc_row container">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <hr/>
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h5 className="tm-custom-heading">Skills required to do Nursing Courses</h5>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <p>Candidates who wish to study Nursing Courses require diverse soft skills and professional skills. The Nursing curriculum and trained teaching faculty play a significant role in teaching, shaping and guiding candidates in the development of their required skills.</p>
                                                      <p><b>Important soft skills required are:</b></p>
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Communication</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Empathy</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Patience</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Kindness</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Gentleness</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Understanding</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Confidence</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Sensitivity</span></li>
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <p style={{marginTop: '20px'}}><b>Important professional skills required are:</b></p>
                                                  </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Adaptability</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Precision</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Aptitude in Science subjects</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Time management</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Strong Work Ethic</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Understanding of Diagnostic Methods and Treatment</span></li>
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Decision-making</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Teamwork</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Networking</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Management patients</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Critical-thinking</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Problem-solving</span></li>
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div className="vc_row-full-width vc_clearfix"></div>

                            <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293977 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-textcolor-white tm-bgimage-position-bottom_center tm-bg tm-bgimage-yes">
                              <div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
                              <div className="vc_row container vc_row-o-equal-height vc_row-flex">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
                                    <div className="vc_column-inner vc_custom_1567664250317 tm-responsive-custom-75919887">
                                      <div className="wpb_wrapper">
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h5 className="tm-custom-heading ">Why should one choose to <br className="hidden-xs"/>study Nursing in Bangalore?</h5>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper study-list">
                                                      <p>The Nursing course is a popular healthcare professional course in Bangalore. It is an interesting course choice because:</p>
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Nursing allows a candidate to make a significant difference through compassion and care for the people in need.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">There are plenty of career opportunities in leading hospitals and healthcare centres in Bangalore.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">There is continuous exposure to diverse working environments in hospitals and clinics in Bangalore.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">There are continuous challenges and opportunities to learn new things.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Candidates can make a career out of helping and caring for people.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Provides instant job satisfaction.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Candidates are appreciated and respected for their professional assistance.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Graduates get to perform many jobs related to the treatment of patients under the direction of qualified and trained Nurses who are recognized in Bangalore.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">There are diverse and popular Nursing specialties in Bangalore that focus on medical areas including Clinical Care, Midwifery, Cardiology, Critical Care, Neonatal Care, Geriatrics, Pediatrics, Oncology, Orthopedics and Occupational Health.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Hospitals and clinics in Bangalore provide a very good salary package with additional benefits.</span></li>
                                                        <li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">The working hours are flexible and regulated - it ensures work-life balance.</span></li>
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                    <div className="wpb_column tm-column vc_column_container vc_col-sm-12 vc_col-has-fill tm-zindex-2 hidden-xs">
                                      <div className="vc_column-inner vc_custom_1567664749067 tm-col-bgimage-yes tm-responsive-custom-94735818">
                                          <div className="tm-col-wrapper-bg-layer tm-bg-layer tm-bgimage-position-center_center">
                                            <div className="tm-bg-layer-inner"></div>
                                          </div>
                                          <div className="wpb_wrapper study-img"> <img data-src="assets/img/study-nursing.png" className="lozad"/> </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div className="vc_row-full-width vc_clearfix"></div>
                          <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                              <div className="vc_row container">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h5 className="tm-custom-heading">Admission Process at Bangalore Colleges</h5>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <p>Students seeking admission to Nursing Courses should have successfully passed minimum level education with good academic record.The admission process in Government and Private colleges and universities for Nursing Courses in Bangalore are of two types:</p>
                                                      <ol className="">
                                                        <li><b>Admission through Merit Quota</b></li>
                                                        <li><b>Admission through Management Quota</b></li>
                                                      </ol>
                                                      <p>Students looking for Government Merit seats can attempt entrance examinations for Nursing admissions, however, these examinations are not mandatory for getting admission into Nursing colleges in Bangalore.</p>
                                                  </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Merit Quota</b></span></li>
                                                        <p>Candidates seeking admission to colleges affiliated to Rajiv Gandhi University of Health Sciences (RGUHS) can undergo the <b>Karnataka Examination Authority (KEA) Counselling</b> through Karnataka CET Exam (KCET) for undergraduate courses or <b>Postgraduate CET Exam (PGCET) Counselling</b> through PGCET for postgraduate courses.</p>
                                                        <p>Candidates looking for specific institution Merit seats have to write Institution-based Entrance Exams if any.</p>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Management Quota</b></span></li>
                                                        <p>Candidates seeking Management seats through Direct Admission to the <a href="https://www.nursingcollegesinbangalore.com/colleges">Top Bsc Colleges in Bangalore</a> (<b>Top Bsc Colleges in Bangalore</b>) can do so under Management Quota. Candidates can reserve seats for admissions in advance through Management Quota.</p>
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                              <div className="vc_row container">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <hr/>
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h5 className="tm-custom-heading">Scholarships for Nursing</h5>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <p>A Nursing scholarship helps to ease the financial burden of candidates who want to pursue a Nursing degree from a prestigious Nursing college or university. These scholarships are available for Indian students who wish to pursue their dream of studying in top Nursing universities. Different types of MBA scholarships and financial aid are offered to Indian students who wish to pursue a Nursing course:</p>
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Merit-based Scholarships – based on excellent academic record</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Merit-cum-Aptitude-based Scholarships - based on excellent academic record and individual aptitude</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Need-based Scholarships and Fellowships – usually for candidates from low income families</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Gender-based Scholarships – usually for female students</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">National Scholarships – provided by national Nursing Colleges</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">State Scholarships – provided by the state government</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Private Scholarships – provided by private Nursing Colleges</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Fee Waivers – usually a specific percentage, from 5% to 100%, of the total fees is waived off</span></li>
                                                      </ul>
                                                      <br/>
                                                      <p><b>It is important to begin research about scholarship application dates and deadlines at the earliest. Steps to avail a scholarship are:</b></p>
                                                      <div className="more-content" style={{display: 'none'}}>
                                                        <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">There are two main categories of scholarships – <b>Government and Private Scholarships</b>. It is very important to choose which type of scholarship you want to avail before proceeding with applications.</span></li>
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Research and monitor regularly about the announcement of scholarship exams notifications and details, application deadlines and eligibility.</span></li>
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Fill the Application Form without missing the last date.</span></li>
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Submit the requested important documents like previous academic transcripts, age and address proof, certificates of participation in sports and extra-curricular activities, proof of parent’s income (if applicable), statement of purpose, etc.</span></li>
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Prepare well for competitive scholarship examinations and answer each question very carefully.</span></li>
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Some universities also conduct an additional Personal Interview round after the examination so it is good to be prepared.</span></li>
                                                        </ul>
                                                        <p style={{marginTop: '20px'}}><b>Some examples of Nursing Scholarships:</b></p>
                                                        <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">CSIR- UGC JRF NET Fellowship</span></li>
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">MPCL Scholarship scheme for Nursing students</span></li>
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">TransUnion CIBIL Scholarship for Girls</span></li>
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Swarna Jayanti Research Fellowships In Science And Technology</span></li>
                                                            <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">American Indian Nurse Scholarship Award Program</span></li>
                                                        </ul>
                                                      </div>
                                                      <a href="#" className="btn more more-btn">Read more</a>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                              <div className="vc_row container">
                                <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                    <div className="vc_column-inner tm-responsive-custom-12751771">
                                      <div className="wpb_wrapper">
                                          <hr/>
                                          <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                            <section className="tm-vc_cta3-container tm-sepcolor-default">
                                                <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                  <div className="tm-vc_cta3_content-container">
                                                      <div className="tm-vc_cta3-content">
                                                        <header className="tm-vc_cta3-content-header tm-wrap">
                                                            <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                              <h5 className="tm-custom-heading">Career Scope of Nursing</h5>
                                                            </div>
                                                            <div className="heading-seperator"><span></span></div>
                                                        </header>
                                                      </div>
                                                  </div>
                                                </div>
                                            </section>
                                          </div>
                                          <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                            <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                                <div className="vc_column-inner  ">
                                                  <div className="wpb_wrapper">
                                                      <p>Nursing graduates have plenty of opportunities in top hospitals and healthcare centres with attractive salary packages. The course provides equal opportunities in both Government and Private sectors.</p>
                                                      <p><b>Nurses can find plenty of opportunities in a number of professional areas including:</b></p>
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Hospitals</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Nursing Homes</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Assisted living and Residential facilities</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Private Practices</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Home Healthcare Centres</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Orphanages</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Old Age Homes</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Military Services</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Childcare Centres</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Veterans Affairs Hospitals</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Clinics</span></li>
                                                      </ul>
                                                      <p style={{marginTop: '20px'}}><b>There is plenty of scope for Nurses. This includes:</b></p>
                                                      <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Graduates can continue to develop their expertise and skills through specializations in diverse health areas like Surgical, Medical, ICU, Telemetry and ER.</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Fresh graduates take general care of patients.</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Senior Nurses are required for managing pediatric, psychiatric and intensive care patients. They also help in setting up medical equipment, keeping records of patient's progress and administration.</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Nurses and Nursing Assistants can assist Doctors in health centers from the general ward to operation theatre.</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">There is a very high demand for qualified and trained Nurses in India and abroad.</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Candidates can pursue healthcare management courses and join the Administrative sector.</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Graduates can become Teachers and Lecturers in health universities and colleges.</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">There are plenty of private and government jobs with a very good pay scale.</span></li>
                                                        <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">There is a heavy demand for qualified, trained and skilled Nurses in countries such as the US, UK, Canada and Oman.</span></li>
                                                      </ul>
                                                  </div>
                                                </div>
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                          <div className="vc_row-full-width vc_clearfix"></div>
                          
                          <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293978 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-textcolor-white tm-bgimage-position-bottom_center tm-bg tm-bgimage-yes">
                            <div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
                            <div className="vc_row container vc_row-o-equal-height vc_row-flex">
                              <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
                                  <div className="vc_column-inner vc_custom_1567664250317 tm-responsive-custom-75919887">
                                    <div className="wpb_wrapper">
                                        <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                          <section className="tm-vc_cta3-container tm-sepcolor-default">
                                              <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                <div className="tm-vc_cta3_content-container">
                                                    <div className="tm-vc_cta3-content">
                                                      <header className="tm-vc_cta3-content-header tm-wrap">
                                                          <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                            <h5 className="tm-custom-heading ">Nursing Placements and Salary Packages</h5>
                                                          </div>
                                                          <div className="heading-seperator"><span></span></div>
                                                      </header>
                                                    </div>
                                                </div>
                                              </div>
                                          </section>
                                        </div>
                                        <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                          <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                              <div className="vc_column-inner  ">
                                                <div className="wpb_wrapper study-list">
                                                    <p>The top educational institutes in Bangalore have excellent and renowned Placement Departments that provide multiple placement opportunities for Nursing graduates. There is plenty of career scope and opportunities for skilled and qualified Nurses. This includes:</p>
                                                    <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Internship opportunities in government and private hospitals, nursing homes, private clinics and healthcare centres</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Opportunity as a Teacher, Professor or Lecturer in government or private colleges and universities</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Nurse In-charge or Chief Nurse in healthcare centres, community centres, orphanages, old age homes and rehabilitation centres</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Nursing Instructor in community centres and mental health centres</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Research Associate in hospitals and scientific facilities</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Nursing Supervisor in fitness and rehabilitation centres</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Chief Nurse in foreign countries like the USA, Canada, UK, Middle Eastern nations and New Zealand</span></li>
                                                    </ul>
                                                    <p style={{marginTop: '20px'}}>Graduates can continue to develop their expertise and skills through additional specializations in diverse health areas. The average salary package for Nursing graduates is around 3.5 LPA to 8 LPA. Graduates can begin their career as Nursing Trainees and Assistants and proceed to become Nursing Supervisors.</p>
                                                    <p><b>The salary for Nursing graduates varies with:</b></p>
                                                    <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Work exposure in diverse specializations</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Work experience</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Higher level of education</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Additional certifications</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Professional skills</span></li>
                                                    </ul>
                                                </div>
                                              </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row-full-width vc_clearfix"></div>

                          <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
                            <div className="vc_row container">
                              <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
                                  <div className="vc_column-inner tm-responsive-custom-12751771">
                                    <div className="wpb_wrapper">
                                        <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
                                          <section className="tm-vc_cta3-container tm-sepcolor-default">
                                              <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
                                                <div className="tm-vc_cta3_content-container">
                                                    <div className="tm-vc_cta3-content">
                                                      <header className="tm-vc_cta3-content-header tm-wrap">
                                                          <div className="tm-vc_cta3-headers tm-wrap-cell">
                                                            <h5 className="tm-custom-heading">Top Nursing Recruitments Companies</h5>
                                                          </div>
                                                          <div className="heading-seperator"><span></span></div>
                                                      </header>
                                                    </div>
                                                </div>
                                              </div>
                                          </section>
                                        </div>
                                        <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
                                          <div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
                                              <div className="vc_column-inner  ">
                                                <div className="wpb_wrapper">
                                                    <p>Nursing graduates have plenty of career opportunities in government and private hospitals institutions, healthcare centres, clinics, community centres, nursing homes and rehabilitation centres.</p>
                                                    <p><b>Popular recruiters of Nursing graduates are:</b></p>
                                                    <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Schools/ Colleges</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Research Institutions</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Narayana Hrudayalaya</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Apollo Hospital</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Fortis Hospital</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Hosmat Hospital</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Help Age India</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Practo</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Medanta Medicity</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Columbia Asia Hospital</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Wockhardt Hospitals</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Max Hospital</span></li>
                                                      <li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Manipal Hospital</span></li>
                                                    </ul>
                                                </div>
                                              </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row-full-width vc_clearfix"></div>

                          <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567685146842 tm-zindex-0 tm-bgcolor-skincolor tm-bgimage-position-center_center first-footer">
                            <div className="vc_row container">
                              <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0">
                                  <div className="vc_column-inner  ">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_raw_code wpb_content_element wpb_raw_html vc_custom_1568379925491 tm-textcolor-white">
                                          <div className="wpb_wrapper">
                                              <h5 className="tm-custom-heading first-footer-text">
                                              <i className="fa fa-graduation-cap" aria-hidden="true"></i> Get Direct Nursing Admission Now, <u><a href="#enquiry" data-toggle="modal">Click here</a></u></h5>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <div className="vc_row-full-width vc_clearfix"></div>

                        </div>
                      </article>
                    </main>
                </div>
              </div>
          </div>
        </div>
        );
    }
}

// export default Home;